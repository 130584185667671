<template>
    <h5 v-if="!hideHeader">{{$t('Layouts')}}</h5>

    <Layouts v-if="dataGridControl?.dataObject?.layoutManager" ref="layoutsListRef" :dataObject="dataGridControl.dataObject"/>
    <SaveLayout class="mt-2" :data-object="dataGridControl.dataObject" @saved="reloadLayoutsList"/>
</template>

<script setup lang="ts">
import { dataGridControlKey } from 'o365.modules.vue.injectionKeys.js';
import { inject, ref, computed } from 'vue';

import SaveLayout from 'o365.vue.components.SaveLayout.vue';
import Layouts from 'o365.vue.components.Layouts.vue';

const props = defineProps<{
    hideHeader?: boolean
}>();

const dataGridControl = inject(dataGridControlKey, null);

const activeLayoutObject = computed(() => {
    return dataGridControl?.value?.dataObject?.layoutManager?.activeLayoutObject;
});

const activeLayout = computed(() => {
    return dataGridControl?.value?.dataObject?.layoutManager?.activeLayout;
});

const activeLayoutItem = computed(() => {
    return dataGridControl?.value?.dataObject?.layoutManager?.activeLayoutItem;
});

const hasActiveLayout = computed(() => {
    return activeLayout.value?.columns || activeLayout.value?.filter || activeLayout.value?.groupBy || activeLayout.value?.sortBy
});

const layoutColumns = computed(() => {
    if (activeLayout.value.columns) {
        const result = [];
        Object.keys(activeLayout.value.columns).forEach(key => {
            result.push({
                colId: key,
                ...activeLayout.value.columns[key]
            });
        });
        return result;
    }
});

const gridColumnsMap = computed(() => {
    return dataGridControl?.value?.dataColumns.columns.filter(x => !x.colId.startsWith('o365_')).reduce((obj, value) => ({
        ...obj,
        [value.colId]: value
    }), {});
});

const includeFilter = ref(true);
const includeSortBy = ref(true);
const includeGroupBy = ref(true);

function saveLayout() {
    dataGridControl.value.dataObject?.layoutManager.saveLayout({
        includeFilter: includeFilter.value,
        includeSortBy: includeSortBy.value,
        includeGroupGy: dataGridControl.value.groupBy ? includeGroupBy.value : false
    });
}

const layoutsListRef = ref(null);

function reloadLayoutsList() {
    layoutsListRef.value?.layoutsDataObject?.load();
}

// -------------------------
// Column properties handling

// Helper function to determine if add property button should be shown for the column
function showAddProperty(layoutCol) {
    return layoutCol != null && 
        !(layoutCol.width === undefined && 
        layoutCol.order === undefined &&
        layoutCol.pinned === undefined &&
        layoutCol.hide === undefined &&
        layoutCol.hideFromChooser === undefined)
}

// Adds property override the given colId column
function addPropertyOverride(propertyName, colId) {

}

// -------------------------
// Bootstrap collapse logic

const columnsCollapseRef = ref(null);
const filterCollapseRef = ref(null);
const sortByCollapseRef = ref(null);
const groupByCollapseRef = ref(null);
const saveCollapseRef = ref(null);

const collapseStates = ref({
    columns: true,
    filter: true,
    sortBy: true,
    groupBy: true,
    save: false
});

function toggleCollapse(type) {
    let element;
    switch (type) {
        case 'columns':
            element = columnsCollapseRef.value;
            break;
        case 'filter':
            element = filterCollapseRef.value;
            break;
        case 'sortBy':
            element = sortByCollapseRef.value;
            break;
        case 'groupBy':
            element = groupByCollapseRef.value;
            break;
        case 'save':
            element = saveCollapseRef.value;
            break;
        default:
            return;
    }
    const collapseControl = window.bootstrap.Collapse.getOrCreateInstance(element);
    collapseControl.toggle();
    collapseStates.value[type] = !collapseStates.value[type];
}

// -------------------------

</script>

<style scoped>
    .layout-field {
        flex-wrap: wrap;
        align-items: stretch;
        border: 1px solid var(--bs-light-border-subtle);
        font-size: .875rem;
        background-color: var(--bs-light-bg-subtle);
    }

    .layout-field span {
        padding: 0.25rem 0.15rem;
    }

    .layout-field input {
        padding: 0.25rem 0.25rem;
        font-size: .875rem;
        border: none;
        background-color: var(--bs-light-bg-subtle);
    }

    .layout-field input:focus {
        outline-width: 0px;
        background-color: rgb(var(--bs-body-bg-rgb));
    }

    .layout-field input:not(input[type='checkbox']) {
        padding-right: 35px;
    }

    .layout-field select {
        padding: 0.25rem 0.25rem;
        padding-right: 35px;
        font-size: .875rem;
        border: none;
        background-color: var(--bs-light-bg-subtle);
    }

    .layout-field select:focus {
        outline-width: 0px;
        background-color: rgb(var(--bs-body-bg-rgb));
    }
</style>